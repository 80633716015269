<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card v-if="terms">
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Terms & Conditions</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="settingsRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <quill-editor id="quil-content" v-model="terms" :options="editorOption" />
              <div id="quill-toolbar" class="d-flex justify-content-end">
                <button class="ql-bold"></button>
                <!-- <button class="ql-underline"></button>
                <button class="ql-link"></button> -->
              </div>
            </b-col>

            <b-col cols="12">
              <b-button type="button" @click="formSubmitted" variant="primary" class="float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

import { quillEditor } from 'vue-quill-editor';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  setup() {
    const DEPARTMENT_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    return {
      loading: true,
      editorOption,
      terms: null,
    };
  },

  watch: {},

  methods: {
    formSubmitted() {
      this.$refs.settingsRules.validate().then((success) => {
        if (success) {
          this.loading = true;

          if (this.terms != null) {
            store
              .dispatch('settings/updateTerms', { terms: this.terms })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Settings Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getSettings();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: 'Please fill in all fields.',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            });

            this.loading = false;
          }
        }
      });
    },

    getSettings() {
      this.loading = true;

      store
        .dispatch('settings/getSettings')
        .then((response) => {
          this.terms = response.data.terms;

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });

          this.$router.push({ name: 'settings-dashboard' });
        });
    },
  },

  created() {
    this.getSettings();
  },

  computed: {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.email-list {
  list-style: none;
  padding: 0;
}

.email-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.email-list li:hover {
  background-color: #ffffff50;
  cursor: pointer;
}

.email-list b-button {
  flex-shrink: 0;
}

#quil-content ::v-deep {
  > .ql-container {
    .ql-editor {
      height: 300px !important;
    }
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    background-color: white;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
